import styled from "styled-components";
import type { LogoProps } from "./LogoProps";
import VippsLogoSrc from "./graphics/vipps-logo_58x15.svg";
import ReactComponent from "./graphics/vipps-logo_58x15.svg?react";

export const VippsLogoSvg = ReactComponent;

const VippsLogo = styled(function ({ width = 180, ...props }: LogoProps) {
  return (
    <img
      {...props}
      src={VippsLogoSrc}
      alt="Vipps"
      width={width}
      height={(15 / 58) * width}
    />
  );
})``;

export default VippsLogo;
